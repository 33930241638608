<template>
  <div class="app-mall-category h" :class="{float: !inline}" @mouseleave="current = null">
    <div class="v bc-l list">
      <div v-for="c in store" :key="c.id" class="padding-10 h c item" :class="{selected: current && current._id === c._id}" @mouseover.stop="current = c" @click.stop="handleGoodsSearch(c)">
        <span class="flex">{{c.name}}</span>
        <span class="el-icon-arrow-right" v-if="c.children && c.children.length"></span>
      </div>
    </div>
    <div class="inner h bc-l">
      <category-column :data="current" v-if="current && current.children" />
    </div>
  </div>
</template>

<script>
import categoryColumn from "./category-column";
import request from "@/utils/request";

export default {
  components: { categoryColumn },
  props: {
    inline: Boolean
  },
  data() {
    return {
      store: [],
      current: null
    };
  },
  methods: {
    loadBrandAndSeries() {
      request({
        url: "api/brand",
        method: "get",
        params: {
          page: 0,
          size: 100000
        }
      }).then(res => {
        res.content.forEach(o => {
          o._type = "brand";
          o._id = "brand_" + o.id;
          o.children = [];
        });
        request({
          url: "api/series",
          method: "get",
          params: {
            page: 0,
            size: 100000,
            enabled: true
          }
        }).then(res1 => {
          (res1.content || []).forEach(o => {
            o._type = "series";
            o._id = "series_" + o.id;
          });
          let ses = this.$buildTree(res1.content, null, { parentField: "pid" });

          ses.forEach(o => {
            let b = res.content.find(_b => {
              return _b.id === o.brandId;
            });
            if (b) {
              b.children.push(o);
            }
          });
          this.store = res.content;
        });
      });
    },
    handleGoodsSearch(row) {
      this.$router.push({
        path: "/mall/retail/list",
        query:
          row._type === "brand"
            ? {
                b: row.id
              }
            : {
                s: row.id
              }
      });
    }
  },
  mounted() {
    this.loadBrandAndSeries();
  }
};
</script>