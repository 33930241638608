<template>
  <div class="app-mall-category-column h">
    <div class="v list scroll-able">
      <div v-for="c in data.children" :key="c.id" class="padding-10 item h c" :class="{selected: current && current._id === c._id}" @mouseover.stop="current = c" @click.stop="handleGoodsSearch(c)">
        <span class="flex">{{c.name}}</span>
        <span class="el-icon-arrow-right" v-if="c.children && c.children.length"></span>
      </div>
    </div>
    <category-column :data="current" v-if="current && current.children" />
  </div>
</template>

<script>
export default {
  name: "category-column",
  props: {
    data: Object
  },
  data() {
    return {
      current: null
    };
  },
  methods: {
    handleGoodsSearch(row) {
      this.$router.push({
        path: "/mall/retail/list",
        query:
          row._type === "brand"
            ? {
                b: row.id
              }
            : {
                s: row.id
              }
      });
    }
  },
  watch: {
    data: {
      handler: function() {
        this.current = null;
      }
    }
  }
};
</script>